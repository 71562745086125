@media only screen and (max-width: 767px) {
  .ui.grid > .stackable.stackable.row > .column,
  .ui.stackable.grid > .column.grid > .column,
  .ui.stackable.grid > .column.row > .column,
  .ui.stackable.grid > .column:not(.row),
  .ui.stackable.grid > .row > .column,
  .ui.stackable.grid > .row > .wide.column,
  .ui.stackable.grid > .wide.column {
    padding: 7px 0 !important;
  }
}

img {
  max-width: 100%;
  max-height: 100%;
}

* {
  line-height: 125%;
}